<template>
  <div class="app-container">
    <div class="detail">
      <div class="index">
        <div class="header">
          <div class="header-title">我的账单</div>
        </div>
        <div class="box basics-box">
          <div class="box-row">
            <div class="box-col">
              <div class="box-item-title">账单日期:</div>
              <div class="box-item-val box-item-wid">{{ billDateFormat }}账单</div>
              <router-link to="/syoung/sale-credit/history-list">
                <el-button class="link" type="text">历史账单</el-button>
              </router-link>
            </div>
            <div class="box-col" v-if="unRepaidAmount && status !== 'PAID'">
              <div class="box-item-title">账单金额:</div>
              <div class="box-item-val box-item-wid">{{unRepaidAmount}}元</div>
            </div>
          </div>
          <div class="box-row" v-if="lastRepaymentDate && status !== 'PAID'">
            <div class="box-col">
              <div class="box-item-title">还款日期:</div>
              <div class="box-item-val">{{ lastRepaymentDate | parseTime('{y}年{m}月{d}日') }}还款</div>
            </div>
          </div>
          <div class="box-row" v-if="!id">
            <div class="box-col">
              <p class="tips">您还未产生账单</p>
            </div>
          </div>
          <div class="box-row" v-if="status === 'PAID'">
            <div class="box-col">
              <p class="tips">账单已还清</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="index">
        <div class="header">
          <div class="header-title">
            账单明细
            <span
              class="detail-time"
            >{{ billStartTime | parseTime('{m}月{d}日') }}-{{ billEndTime | parseTime('{m}月{d}日') }}</span>
          </div>
        </div>
        <div class="box">
          <div class="brand-list-box">
            <template>
              <el-table :data="detailList" style="width: 100%">
                <el-table-column label="订单编号" prop="orderNo"></el-table-column>
                <el-table-column label="商品名称" prop="name"></el-table-column>
                <el-table-column label="商品数量" prop="quantity"></el-table-column>
                <el-table-column label="商品金额（元）" prop="totalAmount"></el-table-column>
                <el-table-column label="退款金额（元）" prop="refundAmount"></el-table-column>
                <el-table-column align="center" label="时间">
                  <template slot-scope="scope">{{ scope.row.payTime | parseDefaultTime }}</template>
                </el-table-column>
                <el-table-column label="订单状态" prop="orderStatusName"></el-table-column>
              </el-table>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDetail } from '@/api/sale-credit';
export default {
  data() {
    return {
      billStartTime: '',
      status: '',
      billEndTime: '',
      unRepaidAmount: '',
      billDate: '',
      lastRepaymentDate: '',
      detailList: []
    };
  },
  computed: {
    id() {
      if (this.$route.params.id === 'undefined') {
        return '';
      }
      return this.$route.params.id;
    },
    billDateFormat() {
      if (this.billDate) {
        return this.billDate.slice(0, 4) + '年' + this.billDate.slice(4, 6) + '月';
      }
      return '';
    }
  },
  mounted() {
    this.fetchDetail();
  },
  methods: {
    fetchDetail() {
      getDetail(this.id).then(res => {
        if (res.success) {
          this.unRepaidAmount = res.data.unRepaidAmount;
          this.billDate = res.data.billDate;
          this.status = res.data.status;
          this.lastRepaymentDate = res.data.lastRepaymentDate;
          this.billStartTime = res.data.billStartTime;
          this.billEndTime = res.data.billEndTime;
          this.detailList = res.data.detailList || [];
        }
      });
    }
  }
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.index {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
  .header-title {
    font-weight: 900;
    /* border-bottom: 1px solid #e8e8e8; */
    font-size: 16px;
    color: #333;
    display: inline-flex;
    align-items: center;
  }
  .actions-time {
    font-size: 12px;
    color: #999;
    margin-right: 10px;
  }
  a {
    font-size: 12px;
    color: #38f;
    text-decoration: none;
  }
}
.divider {
  background-color: #eee;
  width: 100%;
  height: 1px;
  margin: 20px 0;
}
.box {
  padding: 20px;
  /* .row:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -80px;
    width: 45px;
    height: 45px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-image: url(./sprite.png);
  } */
  .row-second:before {
    background-position: 0 -45px;
  }
  .vice-row::before {
    background-image: none;
  }
  .vice-row {
    margin-top: 15px;
  }
  .row {
    margin-left: 80px;
    position: relative;
    display: flex;
    .item {
      flex: 1;
      color: #333;
      font-size: 12px;
      .amount {
        cursor: pointer;
        display: block;
        font-size: 24px;
        margin: 6px 0 10px;
        color: #38f;
        text-decoration: none;
      }
      .amount-not {
        display: block;
        font-size: 24px;
        margin: 6px 0 10px;
        color: #38f;
        text-decoration: none;
      }
      .amount-x {
        color: #f44;
      }
      .amount-yesterday {
        color: #999;
      }
    }
  }
}
.more {
  color: #38f;
  padding-left: 5px;
}
.top-10-container {
  display: flex;
  align-items: flex-start;
  .top-10-card {
    flex: 1;
    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .card-content {
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        &__left {
          display: flex;
          align-items: center;
          img {
            width: 60px;
            height: 60px;
            margin: 0 10px;
          }
        }
      }
    }
  }
  .card-notification {
    margin-right: 2%;
    /deep/ .el-card__body {
      padding: 10px;
    }
    .card-content {
      padding: 5px;
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .biz-title {
      padding: 5px 10px;
      background: #38f;
      color: #fff;
      margin-right: 10px;
      font-size: 12px;
      font-weight: 600;
      min-width: 95px;
      text-align: center;
      border-radius: 5px;
      letter-spacing: 2px;
      &.biz-titleback {
        opacity: 0.6;
      }
    }
    .tip {
      color: #999;
      font-size: 15px;
      text-align: center;
    }
    .biz-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;
      padding: 8px 0;
      margin-bottom: 0;
      font-size: 12px;
      transition: all 0.7s;
      cursor: pointer;
      .el-icon-circle-close {
        color: #666;
        cursor: pointer;
        width: 0;
        transition: width 0.5s, visibility 0s;
        display: inline-block;
        visibility: hidden;
      }
      &:hover {
        .el-icon-circle-close {
          width: 12px;
          visibility: visible;
        }
      }
      /deep/ .el-badge__content.is-fixed.is-dot {
        left: -12px;
      }
      .back {
        color: #999;
      }
    }
    .biz-item-boder {
      border-top: 1px solid #eee;
    }
  }
}

.basics-box {
  .box-row {
    display: flex;
    margin-bottom: 14px;
    color: #333;
    .box-col {
      display: flex;
      margin-right: 25px;
      flex: 1;
    }
    .box-item-title {
      margin-right: 10px;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 15px;
      width: 90px;
    }
    .box-item-val {
      color: rgba(0, 0, 0, 0.65);
      &.box-item-wid {
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          max-width: none;
          white-space: normal;
        }
      }
    }
    /deep/ .el-button {
      padding: 0;
      margin-left: 14px;
    }
  }
}
.brand-list-box {
  overflow: hidden;
  .brand-list-item {
    padding: 8px 20px;
    float: left;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    margin-right: 15px;
    margin-bottom: 8px;
  }
  .not {
    color: #999;
  }
}
.pay {
  display: inline-block;
  margin-left: 15px;
}
.box {
  .item-title {
    height: 35px;
    line-height: 35px;
  }
  .tips {
    font-size: 28px;
    color: #000;
  }
}
.detail-time {
  margin-left: 20px;
}
</style>
