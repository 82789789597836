import { withExtTenantIdRequest } from '@/utils/request';
// 查询账单详情
export function getDetail(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/saleCreditBill/getDetails?id=${id}`,
    method: 'get'
  });
}
// 查询账单列表
export function list(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/saleCreditBill/myListPage`,
    method: 'post',
    data
  });
}
// 商家还款
export function saleCreditRepaymentCreate(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/saleCreditRepaymentRecord/create`,
    method: 'post',
    data
  });
}
// 是否有待审核确认的还款记录
export function myRepaymentInfo() {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/saleCreditRepaymentRecord/myRepaymentInfo`,
    method: 'get'
  });
}
